body {
  background-color: #f7f7f7;
}

.logoutButton {
  float: right;
  box-sizing: border-box;
  padding: 0.35em 0.75em;
  font-size: 0.9rem;
  background-color: #3498db;
  border: 1px solid #2980b9;
  color: #fff;
  border-radius: 5px;
}

main {
  min-width: 400px;
  max-width: 800px;
  margin-top: 1em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;

  border-radius: 5px;
  border-style: solid;
  border-color: #a5a5a5;
  border-width: 1px;

  .errorBox {
    padding: 0.75em 1em;
    border-radius: 5px;
    background-color: hsl(348, 100%, 61%);
    margin: 1em;
    box-sizing: border-box;
    color: white;
  }

  .warningBox {
    padding: 0.75em 1em;
    border-radius: 5px;
    background-color: hsl(44, 84%, 45%);
    margin: 1em;
    box-sizing: border-box;
    color: white;
  }

  .infoBox {
    padding: 0.75em 1em;
    border-radius: 5px;
    background-color: #2980b9;
    margin: 1em;
    box-sizing: border-box;
    color: white;
  }

  .login {
    border-radius: 5px;
    color: black;
    background-color: #f1f1f1;
    border-bottom-color: #d5d5d5;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    box-sizing: border-box;
    margin: 1em;
    padding: 1em 1em;

    label {
      margin-top: 0.5em;
      display: block;
      margin-bottom: 0.5em;
      font-weight: bolder;
    }

    button {
      box-sizing: border-box;
      padding: 0.5em 1em;
      font-size: 1rem;
      background-color: #3498db;
      border: 1px solid #2980b9;
      color: #fff;
      border-radius: 5px;
    }

    button:disabled {
      background-color: lighten(#3498db, 10%);
      border-color: lighten(#2980b9, 10%);
    }

    button:not([disabled]):hover {
      border-color: darken(#2980b9, 10%);
      background-color: darken(#3498db, 10%);
    }

    input {
      box-sizing: border-box;
      width: 100%;
      line-height: 1.5;
      margin-bottom: 0.5em;
      font-size: 1rem;
      padding: 0.5em;
      height: 2.5rem;
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;
      border-color: #666;
    }
  }

  header {
    padding: 0.8em 1em 0.6em 1em;

    font-size: 2em;
    position: relative;

    .headerLogo {
      height: 48px;
      width: auto;
      align-items: center;
      vertical-align: text-bottom;
    }

    .subTitle {
      margin-top: -0.4em;
      font-weight: 600;
    }

    .subTitle .blue {
      color: #3498db;
    }

    .subTitle .orange {
      color: #f68b20;
    }

    .logout {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.4em;
      padding: 0.5em;

      a,
      a:visited {
        color: #333;
      }
    }
  }

  .description {
    border-radius: 5px;
    color: black;
    background-color: #f1f1f1;
    border-bottom-color: #d5d5d5;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    margin: 1em;
    padding: 0.25em 1em;
  }

  .tree {
    padding: 1em;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: 0.5em;

        .domain {
          font-size: 1.3em;
          padding: 0.4em 0.6em;
          background-color: #eee;
          border-radius: 5px 5px 0 5px;
          cursor: pointer;
        }

        ul {
          padding: 0.5em;
          margin-left: 2em;
          background-color: #ddd;
          border-radius: 0 0 10px 10px;

          li {
            padding: 0.4em 0.4em;

            a {
              color: #111;

              &:visited {
                color: #111;
              }
            }
          }
        }
      }
    }
  }
}

footer {
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  color: #555;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@import "loader.scss";
